import styled from "styled-components";

import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";

export const StyledBookModal = styled.div`
  display: flex;
  color: ${Colors.black};
  background-color: ${Colors.white};
  padding-top: ${Rem(40)};
  padding-bottom: ${Rem(50)};
  flex-direction: column;

  @media (${BP.tablet}) {
    padding-top: ${Rem(120)};
    padding-bottom: ${Rem(100)};
  }
`;

export const StyledBookModalRow = styled.div`
  display: flex;
  flex-direction: column;

  @media (${BP.tablet}) {
    flex-direction: row;
  }
`;

export const StyledBookModalImageBlock = styled.div`
  flex-basis: 100%;
`;

export const StyledBookModalImage = styled.img`
  display: block;
  max-height: 200px;
  margin: 0 auto;
`;

export const StyledBookModalTitleBlock = styled.div`
  flex-basis: 100%;
  padding-left: ${Rem(10)};
  padding-top: ${Rem(10)};

  @media (${BP.tablet}) {
    padding-top: ${Rem(0)};
  }
`;

export const StyledBookModalTitle = styled.h4`
  font-size: ${Rem(16)};
  line-height: ${Rem(20)};
  padding-bottom: ${Rem(10)};
`;

export const StyledBookModalBigText = styled.h3`
  font-size: ${Rem(30)};
  line-height: ${Rem(37)};
  padding-bottom: ${Rem(18)};

  @media (${BP.ipad}) {
    font-size: ${Rem(50)};
    line-height: ${Rem(55)};
  }
`;

export const StyledBookModalFormBlock = styled.div`
  padding: ${Rem(15)};
`;

export const StyledBookModalFormError = styled.p`
  color: ${Colors.red};
`;

export const StyledBookModalFormSubmit = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${Rem(24)};
`;

export const StyledBookModalFormInputBlock = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: ${Rem(8)};

  &:first-child&:last-child {
    width: 100%;
  }
`;
